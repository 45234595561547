import { ENV_CONFIG } from "../config/config";
import { create } from "axios";
import { getCookie } from "../helpers/cookieHelper";
// import { orderApi } from "./OrderService";
const API = create({
  baseURL: ENV_CONFIG.ADMIN_BASE_URL,
  withCredentials: true,
  headers: {
    Authorization: `${getCookie("token")}`,
    "Content-Type": "application/json",
  },
});

export const adminAuthApi = {};

adminAuthApi.getAdminRegOpt = async () => {
  try {
    const adminRegData = await API.post("/get-registration-options", {});
    return adminRegData;
  } catch (error) {
    if (error) {
      console.log("Error: ", error);
      return error;
    }
  }
};

adminAuthApi.verify = async (body, challenge) => {
  try {
    const verfiedRes = await API.post("verify-registration-options", {
      response: body,
      challenge,
    });
    return verfiedRes;
  } catch (error) {
    if (error) {
      console.log("Error: ", error);
      return error;
    }
  }
};
