/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link, Redirect, useHistory } from "react-router-dom";
import { getCookie } from "../helpers/cookieHelper";
import { authApi } from "../services/AuthService";
import quantex from "../assets/images/3.png";
export default function signup() {
  const location = useLocation();
  const history = useHistory();
  // const params = useParams()
  const urlParams = new URLSearchParams(location.search);
  const refId = urlParams.get("ref") ? urlParams.get("ref") : "";
  console.log(refId);
  const [email, setEmail] = useState(location.state?.email || "");
  const [emailError, setEmailError] = useState("");
  const [error, setError] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [redirectValue, setRedirectValue] = useState("");
  const [iAgree, setIAgree] = useState(false);
  const [iAgreeError, setIAgreeError] = useState(false);
  const isPwa = window.matchMedia("(display-mode: standalone)");

  useEffect(() => {
    if (getCookie("token")) {
      setRedirect(true);
      setRedirectValue("/");
    }
  }, []);

  const handleEmail = async (e) => {
    await setEmail(e.target.value);
  };

  const handleEnter = async (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleIAgree = async () => {
    await setIAgree(!iAgree);
  };

  const handleSubmit = async () => {
    await setIsLoader(true);
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (email === "" || email === null) {
      await setIsLoader(false);
      await setEmailError("Email is required!");
    } else if (!email || regex.test(email) === false) {
      await setIsLoader(false);
      await setEmailError("Email is not valid!");
      return false;
    } else if (iAgree !== true) {
      await setIsLoader(false);
      await setIAgreeError(
        "Please read and agree to the T&Cs and Privacy Policy!"
      );
      return false;
    } else {
      setEmailError("");
      const data = {
        email,
        refId,
      };
      const getRegister = await authApi.register(data);
      if (getRegister.status === 200) {
        if (
          getRegister.data.success === true &&
          getRegister.data.status === 200
        ) {
          setEmail("");
          await setIsLoader(false);
          history.replace({
            pathname: "/signin",
            state: {
              message:
                "A temporary password has been sent to your email, please use it to sign in for the first time",
            },
          });
        }
        if (
          getRegister.data.status === 500 &&
          getRegister.data.success === false
        ) {
          await setIsLoader(false);
          setEmailError(getRegister.data.errors[0].msg);
        }
        if (
          getRegister.data.status === 302 &&
          getRegister.data.success === false
        ) {
          setError(getRegister.data.errors[0].msg);
          setEmail("");
          await setIsLoader(false);
        }
      }
    }
  };

  return isPwa.matches ? (
    <>
      <div
        className="col-sm-2 col-md-2 side_menu2 swap-header-contr swap-header-contr-pwa"
        style={{ position: "fixed", top: "0px", zIndex: "71000000" }}
      >
        <div
          style={{ display: "flex" }}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <i className=" ion ion-ios-arrow-back" />
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "30px",
          }}
        >
          <img
            src={quantex}
            style={{
              height: "34px",
              marginBlock: "7px",
            }}
          />
        </div>
      </div>
      {isLoader ? (
        <div className="coin-loader-wrap" style={{ zIndex: "999999" }}>
          <div className="coin-loader">
            <span className="coin-loader-img">
              <img src={process.env.REACT_APP_LOADERS} alt="loader" />
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      {redirect && <Redirect to={redirectValue} />}
      <div className="login-page">
        <div className="login-pwa">
          <h2 className="login-title-pwa">Create Account</h2>
          <p className="notice-pwa">
            Please enter your email so we can send you a temporary password
          </p>
          {error !== "" ? <p className="errorMsg">{error}</p> : ""}
          <div className="input-email-pwa" style={{ marginTop: "3rem" }}>
            <i className="fa fa-envelope icon" />
            <input
              className="input-1"
              type="email"
              name="email"
              value={email}
              placeholder="Enter your e-mail"
              onChange={handleEmail}
              onKeyPress={handleEnter}
            />
            {emailError !== "" ? <p className="errorMsg">{emailError}</p> : ""}
          </div>

          <div
            className="formCheck"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                className="form-check-input signup"
                name="iagree"
                id="cb-1"
                onClick={handleIAgree}
                value="checkedValue"
                checked={!!iAgree}
              />
            </div>
            <label
              className="form-check-label plain-text iagree-label"
              htmlFor="cb-1"
            >
              I acknowledge that I have read and agree to the{" "}
              <Link to="/">Terms & Conditions</Link> and{" "}
              <Link to="/">Privacy Policy.</Link>
            </label>
            {iAgreeError !== "" ? (
              <p className="errorMsg">{iAgreeError}</p>
            ) : (
              ""
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "0.5rem",
            }}
          >
            <button
              type="button"
              className="logSubmit-pwa"
              onClick={handleSubmit}
            >
              <i className="fa fa-door-open" /> Create Account
            </button>
          </div>
          <p className="or-label">
            Already have an Account? <Link to="/signin"> Sign in here</Link>
          </p>
        </div>

        <div className="background" />
      </div>
    </>
  ) : (
    <>
      {isLoader ? (
        <div className="coin-loader-wrap" style={{ zIndex: "999999" }}>
          <div className="coin-loader">
            <span className="coin-loader-img">
              <img src={process.env.REACT_APP_LOADERS} alt="loader" />
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      {redirect && <Redirect to={redirectValue} />}
      <div className="login-page">
        <div className="login">
          <h2 className="login-title">Create Account</h2>
          <p className="notice">
            Please enter your email so we can send you a temporary password
          </p>
          {error !== "" ? <p className="errorMsg">{error}</p> : ""}
          <div className="input-email">
            <i className="fa fa-envelope icon" />
            <input
              className="input-1"
              type="email"
              name="email"
              value={email}
              placeholder="Enter your e-mail"
              onChange={handleEmail}
              onKeyPress={handleEnter}
            />
            {emailError !== "" ? <p className="errorMsg">{emailError}</p> : ""}
          </div>

          <div className="formCheck">
            <input
              type="checkbox"
              className="form-check-input"
              name="iagree"
              id="cb-1"
              onClick={handleIAgree}
              value="checkedValue"
              checked={!!iAgree}
            />
            <label className="form-check-label plain-text" htmlFor="cb-1">
              I acknowledge that I have read and agree to the{" "}
              <Link to="/">Terms & Conditions</Link> and{" "}
              <Link to="/">Privacy Policy.</Link>
            </label>
            {iAgreeError !== "" ? (
              <p className="errorMsg">{iAgreeError}</p>
            ) : (
              ""
            )}
          </div>

          <button type="button" className="logSubmit" onClick={handleSubmit}>
            <i className="fa fa-door-open" /> Create Account
          </button>
          <p>
            Already have an Account? <Link to="/signin"> Sign in here</Link>
          </p>
        </div>

        <div className="background" />
      </div>
    </>
  );
}
