/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useLocation, useHistory } from "react-router-dom";
import { authApi } from "../services/AuthService";
import { getCookie } from "../helpers/cookieHelper";
import Cookies from "js-cookie";
import quantex from "../assets/images/3.png";
export default function login(props) {
  const location = useLocation();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [redirectValue, setRedirectValue] = useState("");
  //variable forhandle modal
  // const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    if (getCookie("token")) {
      setRedirect(true);
      setRedirectValue("/");
    }
  }, []);

  const handleEmail = async (e) => {
    await setEmail(e.target.value);
    await setEmailError("");
  };

  const handlePassword = async (e) => {
    await setPassword(e.target.value);
    await setPasswordError("");
  };

  const handleEnter = async (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    await setIsLoader(true);
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (email === "" || email === null) {
      await setIsLoader(false);
      await setEmailError("Email is required!");
    } else if (!email || regex.test(email) === false) {
      await setIsLoader(false);
      await setEmailError("Email is not valid!");
    } else if (password === "") {
      await setIsLoader(false);
      await setPasswordError("Password is required!");
    } else {
      const data = {
        email,
        password,
      };
      console.log("data", data);
      const getLogin = await authApi.login(data);
      console.log("getLogin", getLogin);
      if (getLogin.status === 200) {
        // TO DO Check Admin login flow
        const isAdmin = getLogin.data.isAdmin ? true : false;

        console.log(isAdmin);
        let redirectVerify2fa = "/verify2fa",
          redirectReset = "/reset",
          newUser = "/mfa-verify-new-user";
        if (isAdmin) {
          redirectVerify2fa = "/admin/verify2fa";
          redirectReset = "/admin/reset";
          newUser = "/admin/mfa-verify-new-user";
        }
        if (getLogin.data.ChallengeName === "SOFTWARE_TOKEN_MFA") {
          Cookies.set(
            "email",
            getLogin.data.ChallengeParameters.USER_ID_FOR_SRP,
            { secure: true, sameSite: "strict" }
          );
          Cookies.set("data", getLogin.data.Session, {
            secure: true,
            sameSite: "strict",
          });
          await setRedirectValue(redirectVerify2fa);
          await setIsLoader(false);
          await setRedirect(true);
        }
        if (getLogin.data.ChallengeName === "NEW_PASSWORD_REQUIRED") {
          // NEW_PASSWORD_REQUIRED
          Cookies.set("NEW_PASSWORD_REQUIRED", getLogin.data.Session, {
            secure: true,
            sameSite: "strict",
          });
          await setRedirectValue(redirectReset);
          await setIsLoader(false);
          await setRedirect(true);
        }
        if (getLogin.data.ChallengeName === "MFA_SETUP") {
          Cookies.set("MFA_SETUP", getLogin.data.Session, {
            secure: true,
            sameSite: "strict",
          });

          const mfaSetupData = {
            email,
            session: Cookies.get("MFA_SETUP"),
          };
          const getMfaSetup = await authApi.mfaSetup(mfaSetupData);
          if (getMfaSetup.status === 200) {
            if (getMfaSetup.data) {
              Cookies.remove("MFA_SETUP");
              Cookies.set("EMAIL", getMfaSetup.data.email, {
                secure: true,
                sameSite: "strict",
              });
              Cookies.set("MFA_FOR_NEW_USER", getMfaSetup.data.session, {
                secure: true,
                sameSite: "strict",
              });
              Cookies.set("CODE", getMfaSetup.data.code, {
                secure: true,
                sameSite: "strict",
              });
              Cookies.set("QrCode", getMfaSetup.data.data, {
                secure: true,
                sameSite: "strict",
              });
              await setIsLoader(false);
              history.replace({
                pathname: newUser,
              });
            }
            if (
              getMfaSetup.data.status === 500 &&
              getMfaSetup.data.success === false
            ) {
              setError(getMfaSetup.data.errors[0].msg);
            }
          }
        }
        if (getLogin.data.statusCode === 400) {
          await setIsLoader(false);
          setError(getLogin.data.message);
        }
        if (getLogin.data.status === 500 && getLogin.data.success === false) {
          await setIsLoader(false);
          setError(getLogin.data.errors[0].msg);
        }
      }
    }
  };
  const isPwa = window.matchMedia("(display-mode: standalone)");

  return isPwa.matches ? (
    <>
      <div
        className="col-sm-2 col-md-2 side_menu2 swap-header-contr swap-header-contr-pwa"
        style={{ position: "fixed", top: "0px", zIndex: "71000000" }}
      >
        <div
          style={{ display: "flex" }}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <i className=" ion ion-ios-arrow-back" />
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "30px",
          }}
        >
          <img
            src={quantex}
            style={{
              height: "34px",
              marginBlock: "7px",
            }}
          />
        </div>
      </div>
      {isLoader ? (
        <div className="coin-loader-wrap" style={{ zIndex: "999999" }}>
          <div className="coin-loader">
            <span className="coin-loader-img">
              <img src={process.env.REACT_APP_LOADERS} alt="loader" />
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      {redirect && <Redirect to={redirectValue} />}
      <div className="login-page">
        <div className="login">
          <h2 className="login-title">Login</h2>
          <p className="notice">Please login to trade</p>
          {location.state ? (
            <p style={{ color: "red" }}>{location.state.message}</p>
          ) : (
            ""
          )}
          {error !== "" ? <p className="errorMsg">{error}</p> : ""}
          <div className="input-email-pwa">
            <i className="fa fa-envelope icon" />
            <input
              className="input-1"
              type="email"
              value={email}
              name="email"
              placeholder="Enter your e-mail"
              onChange={handleEmail}
              onKeyPress={handleEnter}
            />
            {emailError !== "" ? <p className="errorMsg">{emailError}</p> : ""}
          </div>
          <div className="input-password-pwa">
            <i className="fa fa-lock icon" />
            <input
              className="input-1"
              type="password"
              value={password}
              name="password"
              placeholder="Enter your password"
              onChange={handlePassword}
              onKeyPress={handleEnter}
            />
            {passwordError !== "" ? (
              <p className="errorMsg">{passwordError}</p>
            ) : (
              ""
            )}
          </div>
          <p style={{ textAlign: "right", padding: "0px", margin: "0px" }}>
            <Link className="forgot-link" to="/forgot-password">
              {" "}
              Forgot password?
            </Link>
          </p>
          <div className="sign-btn-contr-pwa">
            {" "}
            <button
              type="button"
              className="logSubmit-pwa"
              onClick={handleSubmit}
            >
              <i className="fa fa-door-open " /> Sign in
            </button>
          </div>
          <p>
            Don't have an Account?{" "}
            <Link to="/signup" className="signup-btn">
              {" "}
              Sign up here
            </Link>
          </p>

          {/* <ForgotPasswordModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            className='forgot-pass-box'
          /> */}
        </div>

        <div className="background" />
      </div>
    </>
  ) : (
    <>
      {isLoader ? (
        <div className="coin-loader-wrap" style={{ zIndex: "999999" }}>
          <div className="coin-loader">
            <span className="coin-loader-img">
              <img src={process.env.REACT_APP_LOADERS} alt="loader" />
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      {redirect && <Redirect to={redirectValue} />}
      <div className="login-page">
        <div className="login">
          <h2 className="login-title">Login</h2>
          <p className="notice">Please login to trade</p>
          {location.state ? (
            <p style={{ color: "red" }}>{location.state.message}</p>
          ) : (
            ""
          )}
          {error !== "" ? <p className="errorMsg">{error}</p> : ""}
          <div className="input-email">
            <i className="fa fa-envelope icon" />
            <input
              className="input-1"
              type="email"
              value={email}
              name="email"
              placeholder="Enter your e-mail"
              onChange={handleEmail}
              onKeyPress={handleEnter}
            />
            {emailError !== "" ? <p className="errorMsg">{emailError}</p> : ""}
          </div>
          <div className="input-password">
            <i className="fa fa-lock icon" />
            <input
              className="input-1"
              type="password"
              value={password}
              name="password"
              placeholder="Enter your password"
              onChange={handlePassword}
              onKeyPress={handleEnter}
            />
            {passwordError !== "" ? (
              <p className="errorMsg">{passwordError}</p>
            ) : (
              ""
            )}
          </div>

          <button type="button" className="logSubmit" onClick={handleSubmit}>
            <i className="fa fa-door-open " /> Sign in
          </button>
          <p>
            Don't have an Account? <Link to="/signup"> Sign up here</Link>
          </p>
          <p>
            <Link to="/forgot-password"> Forgot password?</Link>
          </p>

          {/* <ForgotPasswordModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            className='forgot-pass-box'
          /> */}
        </div>

        <div className="background" />
      </div>
    </>
  );
}
