/* eslint-disable linebreak-style */
/* eslint-disable import/prefer-default-export */
import { create } from 'axios';
import { authApi } from './AuthService';

const API = create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' },
});

const CryptiswapAPI = create({
  baseURL: process.env.REACT_APP_SWAP_API,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' },
});

API.interceptors.response.use(
  (response) => {
      return response;
  },
  (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      let message;
      if (error && error.response && error.response.status === 404) {
          // window.location.href = '/not-found';
      } else if (error && error.response && error.response.status === 403) {
          window.location.href = '/access-denied';
      } else {
          switch (error.response.status) {
              case 401:
                  authApi.signOut();
                  message = 'Invalid credentials';
                  break;
              case 403:
                  message = 'Access Forbidden';
                  break;
              case 404:
                  message = 'Sorry! the data you are looking for could not be found';
                  break;
              default: {
                  message =
                      error.response && error.response.data ? error.response.data['message'] : error.message || error;
              }
          }
          return Promise.reject(message);
      }
  }
);

const swapApi = {};

swapApi.getSupportedAssets = async () => {
  try {
    const res = await API.get('/swap/get-coins');
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.getCoinRealPrice = async (data) => {
  try {
    const datas = {
      coin_send: data.coinfrom,
      coin_receive: data.cointo,
    };
    // const res = await CryptiswapAPI.post('get_full_rate', datas);
    const res = await API.post('/swap/get-rate', datas);
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.getSubAssets = async (asset) => {
  try {
    const res = await API.get('/swap/find-child-assets-by-asset-id', {
      params: {
        asset
      }
    });
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.checkValidWalletAddress = async (data) => {
  try {
    const res = await API.post('/swap/check-valid-wallet-address', data);
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.getPlaceOrder = async (data) => {
  try {
    const res = await API.post('/swap/create-order', data);
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.getOrderDetails = async (data) => {
  try {
    const res = await API.post('/swap/order', data);
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.getTransactionDetails = async (data) => {
  try {
    const res = await API.get('/swap/transaction-details', { params: { destId: data.destId, assets: data.assets, destAddress: data.destAddress } });
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.addTransactionDetails = async (data) => {
  try {
    const res = await API.post('/swap/store-transaction-details', data);
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.getExternalVaultTransactionDetails = async (data) => {
  try {
    const res = await API.post('/swap/fetch-external-vault-transaction-details', data);
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.getTransactionMinimumAmount = async (data) => {
  try {
    const res = await API.post('/swap/fetch-transaction-minimum-amount', data);
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.updateInvalidTransaction = async (data) => {
  try {
    const res = await API.post('/swap/invalid-transaction', data);
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.getUpdateTransactionStatus = async (data) => {
  try {
    const res = await API.post('/swap/update-order-status', data);
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.getUpdateTransactionExpireStatus = async (data) => {
  try {
    const res = await API.post('/swap/update-order-expire-status', data);
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.updateOrder = async (data) => {
  try {
    const res = await API.post('/swap/update-order', data);
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.getDepositExchange = async (data) => {
  try {
    const res = await API.post('/swap/deposit-exchange', data);
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.getTransactionDetail = async (data) => {
  try {
    const res = await API.post('/swap/fetch-transaction', data);
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.getDepositExchangeTransactionDetail = async (data) => {
  try {
    const res = await API.post('/swap/fetch-deposit-exchange-transaction', data);
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.updateOrderFURA = async (data) => {
  try {
    const res = await API.post('/swap/update-order-fura', data);
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.receiveTransaction = async (data) => {
  try {
    const res = await API.post('/swap/receive-asset', data);
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.updateReceiveTransactionTxHash = async (data) => {
  try {
    const res = await API.post('/swap/update-order-txhash', data);
    return res;
  } catch (error) {
    return error;
  }
};

// swapApi.transfer = async (data) => {
//   try {
//     const res = await API.post('/swap/transfers', data);
//     return res;
//   } catch (error) {
//     return error;
//   }
// };

swapApi.transferDeposit = async (data) => {
  try {
    const res = await API.post('/swap/transfers-deposit', data);
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.transferWithdrawal = async (data) => {
  try {
    const res = await API.post('/swap/transfers-withdrawal', data);
    return res;
  } catch (error) {
    return error;
  }
};

swapApi.updateOrderSendAmount = async (data) => {
  try {
    const res = await API.post('/swap/update-order-send-amount', data);
    return res;
  } catch (error) {
    return error;
  }
};

export { swapApi };
