/* eslint-disable linebreak-style */
/* eslint-disable import/prefer-default-export */
import { create } from 'axios';
import { authApi } from './AuthService';

const API = create({
    baseURL: process.env.REACT_APP_API_URL,
    // headers: { Authorization: `${getCookie('token')}`, 'Content-Type': 'application/json' },
    withCredentials: true,
    headers: { 'Content-Type': 'application/json' },
});



API.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;
        if (error && error.response && error.response.status === 404) {
            // window.location.href = '/not-found';
        } else if (error && error.response && error.response.status === 403) {
            window.location.href = '/access-denied';
        } else {
            switch (error.response.status) {
                case 401:
                    authApi.signOut();
                    message = 'Invalid credentials';
                    break;
                case 403:
                    message = 'Access Forbidden';
                    break;
                case 404:
                    message = 'Sorry! the data you are looking for could not be found';
                    break;
                default: {
                    message =
                        error.response && error.response.data ? error.response.data['message'] : error.message || error;
                }
            }
            return Promise.reject(message);
        }
    }
);

const dashboardApi = {};


dashboardApi.getOrderData = async (data) => {
    try {
        const res = await API.post('/swap/get-third-party-orders', data);
        return res;
    } catch (error) {
        return error;
    }
};

dashboardApi.getCommissionData = async (data) => {
    try {
        const res = await API.post('/swap/get-third-party-commission', data);
        return res;
    } catch (error) {
        return error;
    }
};

export { dashboardApi };