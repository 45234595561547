import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

// images
import feature from '../../../assets/images/3.png';

const Feature = () => {
    return (
        <section className="py-5 mb-xl-5 mb-lg-4 position-relative" data-aos="fade-up">
            <Container>
                <Row className="align-items-center mt-5">
                    <Col lg={5} className="aboutUs">
                        <h1 className="display-5 fw-semibold clrwhite">About us <span>Our Story</span></h1>

                        <p className="mb-4 clrwhite">
                          Quantex was founded in 2020 after much thought by its founder, Andrew Elkhoury. Built on the success of its predecessor Cryptiswap – a simple swap platform – Quantex provides much of what made Cryptiswap a success: the non-custodial, user-friendly swap platform where users can exchange their crypto instantly. In response to customer feedback, our team decided to develop an advanced trading platform. It is the first of its kind to provide both a custodial trading platform and a non-custodial swap platform on the one exchange.
                        </p>
                    </Col>
                    <Col lg={{ span: 6, offset: 1 }}>
                        <div className="img-content2 position-relative mt-4 mt-lg-0">
                            <div className="img-up mb-lg-0 mb-6">
                                <img src={feature} alt="feature" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Feature;
