import Slider from "react-slick";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  getInstruments,
  getInstrumentHistory,
  getTicker,
} from "../actions/market";
export default function MarketCarousel(props) {
  const [isAutoplay, setIsAutoplay] = useState(true);
  const [carouselKey, setCarouselKey] = useState(0);
  // const [instruments, setInstruments] = useState([]);
  // const [tokens, setTokens] = useState(["btc", "eth", "usdt"]);
  const { ticker } = props;
  const handleBestClick = () => {
    setIsAutoplay(false);
    props.getBest();
    setCarouselKey((prevKey) => prevKey + 1); // Update the key to restart the Slider
  };

  const handleWorstClick = () => {
    setIsAutoplay(false);
    props.getWorst();
    setCarouselKey((prevKey) => prevKey + 1); // Update the key to restart the Slider
  };
  // const [tradeList, setTradeList] = useState([]);
  // const [isLoader, setIsLoader] = useState(true);
  // const [isDataLoaded, setIsDataLoaded] = useState(false);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await getInstruments();
  //       setInstruments(res);
  //     } catch (error) {
  //       // Handle error
  //     }
  //   };

  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   if (instruments.length > 0 && !isDataLoaded) {
  //     loadData();
  //     setIsDataLoaded(true);
  //   }
  // }, [instruments]);

  // const loadData = async () => {
  //   let trades = [];
  //   const allTokens = tokens.join(",");
  //   let tickers = await getTicker();
  //   setTickers(tickers);
  //   for (let tIndex = 0; tIndex < tokens.length; tIndex++) {
  //     const token = tokens[tIndex];
  //     let trade = [];
  //     trades.push(trade);
  //   }
  //   setIsLoader(false);
  //   setTradeList(trades);
  //   setTimeout(() => {
  //     loadData();
  //   }, 30000);
  // };
  const settings = {
    key: carouselKey,
    infinite: true,
    speed: 900,
    autoplaySpeed: 3000,
    slidesToShow: 8,
    autoplay: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="market-carousel">
      <Button className="btn m-2 btn-success" onClick={handleBestClick}>
        Best
      </Button>
      <Button className="btn btn-danger" onClick={handleWorstClick}>
        Worst
      </Button>
      <Slider {...settings}>
        {ticker.map(function (tick) {
          var price = tick.close;
          var origin = tick.open;
          var change = price - origin;
          var percentage = (change * 100) / origin;
          return (
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
              <div className="card market-carousel-item p-0">
                <div className="card-body">
                  <div className=" mt-2 mb-2">
                    <div class="row ">
                      <div class="col-6">
                        <span>
                          <img
                            src={`${
                              process.env.REACT_APP_COIN_ICON_URL +
                              tick.instrument
                                .slice(0, -4)
                                .replace(/_+/g, "")
                                .toUpperCase()
                            }.png`}
                            alt=""
                          />
                        </span>
                        <div class="media-body mt-2">
                          {tick.instrument.split("_")[0].toUpperCase()}
                        </div>
                      </div>
                      <div class="col-6">
                        <p
                          className="mb-0"
                          style={{ float: "right", fontSize: "14px" }}
                        >
                          {" "}
                          24h
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" mt-2 mb-1">
                    <div className="row mb-0">
                      <div className="col-8">
                        <p
                          className="fw-bold mb-0"
                          style={{ fontSize: "14px", fontWeight: "500" }}
                        >
                          ${price}
                        </p>
                        {/* <div className='d-flex justify-content-between'>
                          <p style={{fontSize: "16px",fontWeight: "500"}}>${tick.volume}</p>
                          <div >
                          <p className='text-danger' style={{fontSize: "16px",float:"right"}}>-40%</p>
                          </div>
                        </div> */}
                      </div>
                      <div className="col-4">
                        <p
                          className={
                            change < 0 ? "text-danger" : "text-success"
                          }
                          style={{ fontSize: "14px", float: "right" }}
                        >
                          {percentage.toFixed(2)}%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
