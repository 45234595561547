import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

const CustomDropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = (e) => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
  };
  const handleOnClick = (e) => {
    setIsOpen(!isOpen);
  };
  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  {
    return window?.innerWidth > 990 ? (
      <Dropdown
        show={isOpen}
        className="d-inline-block"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Dropdown.Toggle
          variant="default"
          className="btn"
          onClick={handleToggle}
          id="dropdown-basic"
        >
          {props.title}
        </Dropdown.Toggle>
        <Dropdown.Menu>{props.children}</Dropdown.Menu>
      </Dropdown>
    ) : (
      <Dropdown
        show={isOpen}
        className="d-inline-block"
        onClick={handleOnClick}
      >
        <Dropdown.Toggle
          variant="default"
          className="btn"
          onClick={handleToggle}
          id="dropdown-basic"
        >
          {props.title}
        </Dropdown.Toggle>
        <Dropdown.Menu>{props.children}</Dropdown.Menu>
      </Dropdown>
    );
  }
};

export default CustomDropdown;
