import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Login = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/admin/login") {
      window.location.href = "/admin/signin";
    } else {
      window.location.href = "/signin";
    }
  }, []);
  return null;
};

export default Login;
