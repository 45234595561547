/* eslint-disable linebreak-style */
/* eslint-disable import/prefer-default-export */

import { create } from "axios";
import { getCookie } from "../helpers/cookieHelper";
import { authApi } from "./AuthService";

const API = create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `${getCookie("token")}`,
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    if (error && error.response && error.response.status === 404) {
      // window.location.href = '/not-found';
    } else if (error && error.response && error.response.status === 403) {
      // window.location.href = '/access-denied';
    } else {
      switch (error.response.status) {
        case 401:
          authApi.signOut();
          message = "Invalid credentials";
          break;
        case 403:
          message = "Access Forbidden";
          break;
        case 404:
          message = "Sorry! the data you are looking for could not be found";
          break;
        default: {
          message =
            error.response && error.response.data
              ? error.response.data["message"]
              : error.message || error;
        }
      }
      return Promise.reject(message);
    }
  }
);

const orderApi = {};
const xmrbalAPI ={};
const xmrTransAPI ={};

orderApi.getPendingOrders = async () => {
  try {
    const res = await API.post("/admin/get-pending-orders", {});
    return res;
  } catch (error) {
    return error;
  }
};


orderApi.getApprovePendingOrders = async () => {
  try {
    const res = await API.post("/admin/get-approve-transaction-options", {});
    return res;
  } catch (error) {
    return error;
  }
};

orderApi.approvePendingOrders = async (body) => {
  try {
    const res = await API.post("admin/approve-transaction", body);
    return res;
  } catch (error) {
    return error;
  }
};
// XMR balance API
xmrbalAPI.getXmrBalance = async () => {
  try {
    const res = await API.post("/admin/get-xmr-balance", {});
    return res;
  } catch (error) {
    return error;
  }
};
// xmr Transaction API
xmrTransAPI.getXmrTrans = async (body) => {
  try {
    const res = await API.post("admin/get-xmr-transactions", body);
    console.log('getXmrTrans', res);
    return res;
  } catch (error) {
    return error;
  }
};


export { orderApi, xmrbalAPI,xmrTransAPI };
