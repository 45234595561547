import React, { Component } from "react";
import {
  Navbar,
  Nav,
  Dropdown,
  Accordion,
  NavLink,
  Form,
  Button,
} from "react-bootstrap";
import { Link, withRouter, Redirect } from "react-router-dom";
import { ThemeConsumer } from "../../context/ThemeContext";
import QuantexLogo from "../../assets/images/quantex-logo.svg";
import { authApi } from "../../services/AuthService";
import { getCookie } from "../../helpers/cookieHelper";
import CustomDropdown from "../CustomDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navShow: false,
      redirect: false,
      redirectValue: "",
      isSignIn: getCookie("signin"),
      navLinks: [
        {
          type: "theme",
          title: "Dark Mode",
        },
        {
          title: "Home",
          path: "/",
          type: "link",
        },
        {
          links: [
            {
              title: "Trade",
              path: `${process.env.REACT_APP_TRADE_PLATFORM}`,
              type: "link",
            },
          ],
          type: "linkWithChild",
          title: "Markets",
        },
        {
          title: "Swap",
          path: "/swap/BTC/ETH/0.01",
          type: "link",
        },
        {
          links: [
            {
              title: "Help",
              path: `https://help.myquantex.com`,
              type: "link",
            },
            {
              title: "Contact",
              path: `https://help.myquantex.com/contact`,
              type: "link",
            },
            {
              title: "FAQ",
              path: `https://help.myquantex.com/faq/general/`,
              type: "link",
            },
          ],
          type: "linkWithChild",
          title: "Support",
        },
      ],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleInputChange(event) {
    if (this.props.onSearch) {
      this.props.onSearch(this.state.query);
    } else {
      console.error("onSearch function is not defined");
    }
  }
  componentDidMount() {
    // if (localStorage.getItem("NEW_PASSWORD_REQUIRED")) {
    //   this.setState({ redirect: true });
    //   this.setState({ redirectValue: "/reset" });
    // }
    // if (
    //   localStorage.getItem("MFA_FOR_NEW_USER") &&
    //   localStorage.getItem("CODE")
    // ) {
    //   this.setState({ redirect: true });
    //   this.setState({ redirectValue: "/mfa-verify-new-user" });
    // }

    console.log("getCookie('signin')", document.cookie);
    console.log("getCookie('signin')", getCookie("signin"));
    let el = document.querySelector("#darkTheme");
    if (el) {
      el.addEventListener("click", function () {
        document.body.classList.toggle("dark");
      });
    }

    let mobileEl = document.querySelector("#darkMobileTheme");
    if (mobileEl) {
      mobileEl.addEventListener("click", function () {
        document.body.classList.toggle("dark");
      });
    }
  }

  handleSignOut = async () => {
    // const data = {
    //   authorisationToken: getCookie("token"),
    // };
    await authApi.signOut();
    // const getSignOut = await authApi.signOut(data);
  };

  render() {
    const path = window.location.pathname.split("/");
    const isPwa = window.matchMedia("(display-mode: standalone)");

    return (
      <>
        {this.state.redirect && <Redirect to={this.state.redirectValue} />}
        {isPwa?.matches ? (
          <>
            <header className="header-pwa">
              <div
                className="side-nav-contr"
                style={{ float: "right", margin: "5px" }}
              >
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  onClick={() => {
                    this.setState({ navShow: true });
                  }}
                />
              </div>
              {(window.location.pathname === "/" ||
                window.location.pathname === "/dashboard") && (
                <div className="searchbar">
                  <div className="notification-icon-contr">
                    <i className="ion ion-md-notifications" />
                    <div className="alert"></div>
                  </div>
                  <div className="search-input-contr">
                    <div className="search-icon-contr">
                      <span
                        className="input-group-text"
                        id="inputGroup-sizing-sm"
                      >
                        <i className="icon ion-md-search"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control search-input"
                      placeholder="Search Coin pairs"
                      aria-describedby="inputGroup-sizing-sm"
                      onChange={(e) => {
                        this.handleInputChange(e.target.value);
                      }}
                    />
                  </div>
                </div>
              )}
              {path.includes("swap") && (
                <div className="searchbar">
                  <p style={{ fontSize: "20px", fontWeight: "700" }}>
                    Exchange
                  </p>
                </div>
              )}
              <div className="person-btn-contr">
                <i
                  className="ion ion-md-person"
                  style={{ fontSize: "16px" }}
                  onClick={() => {
                    window.location = "/signin";
                  }}
                ></i>
              </div>
            </header>
            <Offcanvas
              show={this.state.navShow}
              onHide={() => {
                this.setState({ navShow: false });
              }}
              data-bs-theme="dark"
            >
              <Offcanvas.Header>
                <Offcanvas.Title style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="primary"
                      style={{ padding: "10px 50px" }}
                      onClick={() => {
                        window.location = "/signin";
                      }}
                    >
                      Sign in
                    </Button>
                  </div>

                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "14px",
                      margin: "0px",
                      marginBlock: "7px",
                    }}
                    className="auth-opt"
                  >
                    Don't have an Account?
                  </p>

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="primary"
                      style={{ padding: "10px 50px" }}
                      onClick={() => {
                        window.location = "/signup";
                      }}
                    >
                      Sign Up
                    </Button>
                  </div>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                {this.state.navLinks.map((item, index) =>
                  item?.links && item.type === "linkWithChild" ? (
                    <Accordion className="custom-accordion">
                      <Accordion.Item
                        className="accordion-item-nav "
                        style={{ padding: "0px" }}
                        key={index}
                        eventKey={index.toString()}
                      >
                        <Accordion.Header className="accordion-header-nav">
                          <p
                            style={{
                              width: "100%",
                              float: "left",
                              marginLeft: "5px",
                              marginBottom: "0px",
                            }}
                          >
                            {item.title}
                          </p>
                        </Accordion.Header>
                        <Accordion.Body>
                          {item?.links?.map((l, idx) => (
                            <NavLink
                              key={idx}
                              href="#"
                              onClick={() => {
                                window.location = l.path;
                              }}
                            >
                              {l.title}
                            </NavLink>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ) : item.type === "theme" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className="navlink"
                    >
                      <p
                        style={{
                          margin: "0px",
                          width: "100%",
                          marginLeft: "5px",
                          padding: "5px",
                          fontSize: "1rem",
                        }}
                      >
                        {item.title}
                      </p>
                      <ThemeConsumer>
                        {({ data, update }) => {
                          return (
                            <Form.Check
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: "0px",
                                padding: "0px",
                              }}
                              type="switch"
                              checked={data.theme !== "dark"}
                              onChange={(e) => {
                                console.log(data.theme === "dark", data.theme);
                                if (!e.target.checked) {
                                  document
                                    .getElementsByTagName("body")[0]
                                    .classList.remove("dark");
                                } else {
                                  document
                                    .getElementsByTagName("body")[0]
                                    .classList.add("dark");
                                }
                                update();
                              }}
                            />
                          );
                        }}
                      </ThemeConsumer>
                    </div>
                  ) : (
                    <NavLink
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      href={item.path}
                      className="navlink"
                    >
                      <p
                        style={{
                          margin: "0px",
                          width: "100%",
                          marginLeft: "5px",
                          padding: "5px",
                          fontSize: "1rem",
                        }}
                      >
                        {item.title}
                      </p>
                    </NavLink>
                  )
                )}
              </Offcanvas.Body>
            </Offcanvas>
          </>
        ) : (
          <header className="light-bb">
            <Navbar expand="lg">
              <Link className="navbar-brand" to="/">
                <img src={QuantexLogo} alt="Quantex Logo" />
              </Link>
              <div>
                <Nav
                  className="navbar-nav ml-auto"
                  style={{
                    flexDirection: "row",
                    float: "left",
                    marginTop: "5%",
                  }}
                >
                  <Dropdown
                    className="header-custom-icon home-header-icon mobile-version"
                    style={{ marginTop: "4%" }}
                  >
                    <ThemeConsumer>
                      {({ data, update }) => (
                        <div
                          role="presentation"
                          variant="default"
                          onClick={update}
                          id="darkMobileTheme"
                          style={{ margin: "10% -80%", paddingRight: "5px" }}
                        >
                          {data.theme === "light" ? (
                            <i className="fa fa-moon-o"></i>
                          ) : (
                            <i className="fa fa-sun-o"></i>
                          )}
                        </div>
                      )}
                    </ThemeConsumer>
                  </Dropdown>
                  {/* {getCookie("token") ? ( */}
                  {getCookie("signin") ? (
                    <>
                      <Link
                        className="nav-link home-signupbtn mobile-version"
                        to="#"
                        onClick={this.handleSignOut}
                      >
                        Sign Out
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        className="nav-link home-signupbtn mobile-version"
                        to="/signup"
                      >
                        Sign Up
                      </Link>
                    </>
                  )}
                </Nav>
                <div style={{ float: "right", marginLeft: "5px" }}>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                </div>
              </div>

              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="navbar-nav mr-auto">
                  <Link to="/markets" className="nav-link">
                    Markets
                  </Link>
                  <a
                    href={`${process.env.REACT_APP_TRADE_PLATFORM}`}
                    className="nav-link"
                  >
                    Trade
                  </a>
                  <Link to="/swap/BTC/ETH/0.01" className="nav-link">
                    Swap
                  </Link>
                  <CustomDropdown title="Support">
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://help.myquantex.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Help
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://help.myquantex.com/contact"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Contact
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://help.myquantex.com/faq/general/"
                        target="_blank"
                        rel="noopener noreferrer"
                      ></a>
                    </li>
                  </CustomDropdown>
                  <CustomDropdown title="More">
                    <li>
                      <Link class="dropdown-item" to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://blog.myquantex.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Blog
                      </a>
                    </li>
                    <li>
                      <Link class="dropdown-item" to="/security">
                        Security
                      </Link>
                    </li>
                    <li>
                      <Link class="dropdown-item" to="/fees">
                        Fees
                      </Link>
                    </li>
                    <li>
                      <Link to="/partner-dashboard" class="dropdown-item">
                        Partners
                      </Link>
                    </li>
                  </CustomDropdown>
                </Nav>
                <Nav className="navbar-nav ml-auto" style={{ float: "left" }}>
                  <Dropdown className="header-custom-icon home-header-icon desktop-version">
                    <ThemeConsumer>
                      {({ data, update }) => (
                        <div
                          role="presentation"
                          variant="default"
                          onClick={update}
                          id="darkTheme"
                        >
                          {data.theme === "light" ? (
                            <i className="fa fa-moon-o"></i>
                          ) : (
                            <i className="fa fa-sun-o"></i>
                          )}
                        </div>
                      )}
                    </ThemeConsumer>
                  </Dropdown>
                  {/* {getCookie("token") ? ( */}
                  {getCookie("signin") ? (
                    <>
                      <Link
                        className="nav-link home-signinbtn"
                        to="#"
                        onClick={this.handleSignOut}
                      >
                        Sign Out
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        className="nav-link home-signinbtn mr-3"
                        to="/signin"
                      >
                        Sign In
                      </Link>
                      <Link className="nav-link home-signupbtn" to="/signup">
                        Sign Up
                      </Link>
                    </>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </header>
        )}
      </>
    );
  }
}
export default withRouter(Header);
