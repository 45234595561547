import { useEffect, useState } from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import { Tabs, Tab } from "react-bootstrap";
import { getFees } from "../../../actions/market";
import { swapApi } from "../../../services/SwapService";
import axios from "axios";
import { ThemeConsumer } from "../../../context/ThemeContext";

export default function Fees() {
  const [key, setKey] = useState("home");
  const [isLoader, setIsLoader] = useState(true);
  const [fees, setFees] = useState([]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await getFees();
  //       const promises = res.data.map((data: any) =>
  //         axios
  //           .get(`${process.env.REACT_APP_API_URL}/swap/find-child-assets-by-asset-id`, {
  //             params: {
  //               asset: data.assetid.toUpperCase(),
  //             },
  //           })
  //           .then((response) => response.data.data)
  //           .catch((error) => {
  //             console.log(error);
  //             return []; // Return an empty array in case of error
  //           })
  //       );

  //       const responses = await Promise.all(promises);

  //       const mergedData = res.data.map((data: any, index: number) => {
  //         const subResponse = responses[index];
  //         const mergedItem = { ...data, subResponse };

  //         return mergedItem;
  //       });
  //       console.log(mergedData,"merged");

  //       const filteredData = mergedData.reduce((accumulator, data) => {
  //         const existingItemIndex = accumulator.findIndex((item) => item.assetid === data.assetid);

  //         if (existingItemIndex === -1) {
  //           accumulator.push(data);
  //         } else if (data.subResponse.length > 0) {
  //           accumulator[existingItemIndex].subResponse = data.subResponse;
  //         }

  //         return accumulator;
  //       }, []);
  //       console.log(filteredData,"  ");

  //       setFees(filteredData);
  //       setIsLoader(false);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getFees();
        const mergedData = res.data.reduce((accumulator: any, entry: any) => {
          const existingEntry = accumulator.find(
            (item: any) => item.assetId === entry.assetid
          );

          if (existingEntry) {
            existingEntry.networks.push({
              fees: entry.fees,
              id: entry.id,
              minwithdraw: entry.minwithdraw,
              name: entry.name,
            });
          } else {
            accumulator.push({
              assetId: entry.assetid,
              fullname: entry.fullname,
              networks: [
                {
                  fees: entry.fees,
                  id: entry.id,
                  minwithdraw: entry.minwithdraw,
                  name: entry.name,
                },
              ],
            });
          }

          return accumulator;
        }, []);
        setFees(mergedData);
        setIsLoader(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {isLoader ? (
        <div className="coin-loader-wrap">
          <div className="coin-loader">
            <span className="coin-loader-img">
              <img src={process.env.REACT_APP_LOADERS} />
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="p-3">
        <div className="container-fluid">
          <div className="row">
            <ThemeConsumer>
              {({ data, update }) => (
                <div
                  className={`col-md-12 ${
                    data.theme === "dark" && "fees-container"
                  }`}
                >
                  <h3 className="fw-bold">Fees</h3>
                  <div className="">
                    <Tabs defaultActiveKey="third" className="border-0 ">
                      <Tab eventKey="first" title="Trade" color="text-light">
                        <div className="card border-0 rounded-0">
                          <div className="row justify-content-center">
                            <div className="col-md-5">
                              <div className="row mt-3 mb-3">
                                <div className="col-md-6 p-2">
                                  <div className="card fees-card">
                                    <div className="card-header text-center bg-white">
                                      <h5 className="text-primary fw-bold pb-2">
                                        Maker
                                      </h5>
                                      <div className="fw-bold  pb-2">
                                        <span className="h2 fw-bold fees">
                                          0.1%
                                        </span>
                                      </div>
                                    </div>
                                    <div className="card-body">
                                      <ul>
                                        <li className="mb-3">
                                          Flat fee of 0.1% for all market orders
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 p-2">
                                  <div className="card fees-card">
                                    <div className="card-header text-center bg-white">
                                      <h5 className="text-primary fw-bold pb-2">
                                        Taker
                                      </h5>
                                      <div className="fw-bold  pb-2">
                                        <span className="h2 fw-bold fees">
                                          0.1%
                                        </span>
                                      </div>
                                    </div>
                                    <div className="card-body">
                                      <ul>
                                        <li className="mb-3">
                                          Flat fee of 0.1% for all limit orders
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="second" title="Swap">
                        <div className="card border-0 rounded-0">
                          <div className="row mt-3 mb-3 justify-content-center">
                            <div className="col-md-3 p-2">
                              <div className="card fees-card">
                                <div className="card-header text-center bg-white">
                                  <h5 className="text-primary fw-bold pb-2">
                                    Swap
                                  </h5>
                                  <div className="fw-bold  pb-2">
                                    <span className="h2 fw-bold fees">
                                      0.5-1% + Exchange Fee
                                    </span>
                                  </div>
                                </div>
                                <div className="card-body">
                                  <ul>
                                    <li className="mb-3">
                                      + fixed network fee
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="third" title="Withdrawal">
                        <div className="card border-0 rounded-0">
                          <div className="row mt-3 mb-3 justify-content-center">
                            <div className="col-md-12 p-2">
                              <table className="table table-hover tab-4 markets-pair-list">
                                <thead>
                                  <tr>
                                    <th>Coin/Token</th>
                                    <th>Full Name</th>
                                    <th>Network</th>
                                    <th>Minimum Withdrawal</th>
                                    <th>Deposit Fee</th>
                                    <th>Withdrawal Fee</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fees?.map((fee: any, index) => (
                                    <tr key={index}>
                                      <td>
                                        <img
                                          src={`${
                                            process.env
                                              .REACT_APP_COIN_ICON_URL +
                                            fee?.assetId?.toUpperCase()
                                          }.png`}
                                        />
                                        {fee?.assetId?.toUpperCase()}
                                      </td>
                                      <td>{fee?.fullname}</td>
                                      {fee?.networks?.length > 0 && (
                                        <td>
                                          {fee?.networks?.map(
                                            (network: any) => (
                                              <p key={network.id}>
                                                {" "}
                                                {network?.name
                                                  ? network?.name?.toUpperCase()
                                                  : fee?.assetId.toUpperCase()}{" "}
                                              </p>
                                            )
                                          )}
                                        </td>
                                      )}
                                      {fee?.networks?.length > 0 && (
                                        <td>
                                          {fee?.networks?.map(
                                            (network: any) => (
                                              <p key={network.id}>
                                                {" "}
                                                {Number(
                                                  network?.minwithdraw
                                                )}{" "}
                                              </p>
                                            )
                                          )}
                                        </td>
                                      )}
                                      {fee?.networks?.length > 0 && (
                                        <td>
                                          {fee?.networks?.map(
                                            (network: any) => (
                                              <p key={network.id}> Free </p>
                                            )
                                          )}
                                        </td>
                                      )}
                                      {fee?.networks?.length > 0 && (
                                        <td>
                                          {fee?.networks?.map(
                                            (network: any) => (
                                              <p key={network.id}>
                                                {" "}
                                                {Number(network?.fees)}{" "}
                                              </p>
                                            )
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              )}
            </ThemeConsumer>
          </div>
        </div>
      </div>
    </>
  );
}
