import React, { useEffect, useState } from "react";
import { Tabs, Tab, Button } from "react-bootstrap";
import {
  getInstruments,
  getInstrumentHistory,
  getTicker,
} from "../actions/market";
import MarketCarousel from "../components/MarketCarousel";
export default function MarketsList() {
  const [instruments, setInstruments] = useState([]);
  const [tokens, setTokens] = useState(["all", "btc", "usdt"]);
  const [tradeList, setTradeList] = useState([]);
  const [starList, setStarList] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [tickerUsdt, setTickerUsdt] = useState([]);
  const [tickers, setTickers] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      console.log("remove aos");
      await getInstruments()
        .then((res) => {
          setInstruments(res);
        })
        .catch(() => {});
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (instruments?.length && !isDataLoaded) {
      loadData();
      setIsDataLoaded(true);
    }
  }, [instruments]);

  async function loadData() {
    let trades = [];
    let tickers = await getTicker();
    setTickers(tickers);
    for (let tIndex = 0; tIndex < tokens.length; tIndex++) {
      const token = tokens[tIndex];
      let trade = [];
      let usdt_price = [];
      let filtered_instruments = instruments.filter((instrument) =>
        instrument.includes("_" + token)
      );
      for (let iIndex = 0; iIndex < filtered_instruments.length; iIndex++) {
        const instrument = filtered_instruments[iIndex];
        tickers.forEach((t) => {
          if (instrument === t.instrument) {
            t.price = t.close;
            t.origin = t.open;
            t.change = t.price - t.origin;
            t.percentage = (t.change * 100) / t.origin;
            trade.push(t);
            if (instrument?.includes("usdt")) {
              usdt_price.push(t);
              setTickerUsdt(usdt_price);
            }
            if (instrument === "btc_usdt") {
              console.log("price=" + t.price);
            }
          }
        });
      }
      trades.push(trade);
    }
    setIsLoader(false);
    for (const trade of trades) {
      trades[0].push(...trade);
    }
    setTradeList(trades);
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (!isLoader) {
        loadData();
      }
    }, 20000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  function getBest() {
    const sortedTradeList = tickerUsdt
      .slice()
      .sort((a, b) => b.percentage - a.percentage)
      .slice(0, 8);
    setTickerUsdt(sortedTradeList);
  }

  function getWorst() {
    const sortedTradeList = tickerUsdt
      .slice()
      .sort((a, b) => a.percentage - b.percentage)
      .slice(0, 8);
    setTickerUsdt(sortedTradeList);
  }

  return (
    <>
      {isLoader ? (
        <div className="coin-loader-wrap">
          <div className="coin-loader">
            <span className="coin-loader-img">
              <img src={process.env.REACT_APP_LOADERS} />
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="markets pb70">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <MarketCarousel
                getBest={getBest}
                getWorst={getWorst}
                ticker={tickerUsdt}
              />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="float-end">
                {/* <Button className="btn buy m-2" onClick={getBest}>Best</Button>
                  <Button className="btn sell" onClick={getWorst}>Worst</Button> */}
              </div>
              <div className="markets-pair-list">
                <Tabs defaultActiveKey="btc">
                  <Tab eventKey="favorites" title="★ Favorites">
                    <div className="table-responsive">
                      <table className="table star-active">
                        <thead>
                          <tr>
                            <th>Pairs</th>
                            <th>Coin</th>
                            <th>Last Price</th>
                            <th>Change (24H)</th>
                            <th>High (24H)</th>
                            <th>Low (24h)</th>
                            <th>Volume (24h)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {starList?.map((star, index) => {
                            let trade =
                              tradeList[star.tokenIndex][star.tradeIndex];
                            var token = tokens[star.tokenIndex];
                            return (
                              <tr data-href="exchange-light.html" key={index}>
                                <td>
                                  <i className="icon ion-md-star"></i>{" "}
                                  {trade.instrument
                                    .toUpperCase()
                                    .replace("_", "/")}
                                </td>
                                <td>
                                  {/*
                                  <img src={'img/icon/'+(trade.instrument.replace('_'+token,''))+'.png'} alt={trade.instrument.replace('_'+token,'').toUpperCase()} /> {trade.instrument.replace('_'+token,'').toUpperCase()}
                                  src={`${process.env.REACT_APP_COIN_ICON_URL+(coin_send.toUpperCase())}.png`}
                                  */}
                                  <img
                                    src={`${
                                      process.env.REACT_APP_COIN_ICON_URL +
                                      trade.instrument
                                        .replace("_" + token, "")
                                        .toUpperCase()
                                    }.png`}
                                    alt={trade.instrument
                                      .replace("_" + token, "")
                                      .toUpperCase()}
                                  />{" "}
                                  {trade.instrument
                                    .replace("_" + token, "")
                                    .toUpperCase()}
                                </td>
                                <td>{trade.price}</td>
                                <td
                                  className={trade.change < 0 ? "red" : "green"}
                                  style={{
                                    color: trade.change < 0 ? "red" : "green",
                                  }}
                                >
                                  {trade.percentage.toFixed(2)}%
                                </td>
                                <td>{trade.high}</td>
                                <td>{trade.low}</td>
                                <td>{trade.volume.toFixed(4)}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Tab>
                  {tokens.map((token, tindex) => {
                    return (
                      <Tab
                        eventKey={token}
                        title={token.toUpperCase()}
                        key={tindex}
                      >
                        <div className="table-responsive">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Pairs</th>
                                {/* <th
                                  
                                >
                                  Coin
                                </th> */}
                                <th>Last Price</th>
                                <th>Change (24H)</th>
                                <th>High (24H)</th>
                                <th>Low (24h)</th>
                                <th>Volume (24h)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tradeList[tindex]?.map((trade, index) => (
                                <tr data-href="exchange-light.html" key={index}>
                                  <td>
                                    <i
                                      className={
                                        "icon ion-md-star" +
                                        (starList.filter(
                                          (star) =>
                                            star.tokenIndex === tindex &&
                                            star.tradeIndex === index
                                        ).length
                                          ? "active"
                                          : "")
                                      }
                                      onClick={() => {
                                        let list = starList;
                                        if (
                                          starList.filter(
                                            (star) =>
                                              star.tokenIndex === tindex &&
                                              star.tradeIndex === index
                                          ).length
                                        ) {
                                          setStarList(
                                            starList.filter(
                                              (star) =>
                                                !(
                                                  star.tokenIndex === tindex &&
                                                  star.tradeIndex === index
                                                )
                                            )
                                          );
                                        } else {
                                          starList.push({
                                            tokenIndex: tindex,
                                            tradeIndex: index,
                                          });
                                          setStarList(starList);
                                        }
                                      }}
                                    ></i>{" "}
                                    {trade.instrument
                                      .toUpperCase()
                                      .replace("_", "/")}
                                  </td>
                                  {/* <td>
                                    <img
                                      src={`${
                                        process.env.REACT_APP_COIN_ICON_URL +
                                        trade.instrument
                                          .split("_")[0]
                                          .toUpperCase()
                                      }.png`}
                                      alt={trade.instrument
                                        .replace("_" + token, "")
                                        .toUpperCase()}
                                    />{" "}
                                    {trade.instrument
                                      .split("_")
                                      .join("/")
                                      .toUpperCase()}
                                  </td> */}
                                  <td>{trade.price}</td>
                                  <td
                                    className={
                                      trade.change < 0 ? "red" : "green"
                                    }
                                    style={{
                                      color: trade.change < 0 ? "red" : "green",
                                    }}
                                  >
                                    {trade.percentage.toFixed(2)}%
                                  </td>
                                  <td>{trade.high}</td>
                                  <td>{trade.low}</td>
                                  <td>{trade.volume.toFixed(4)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Tab>
                    );
                  })}
                  <div className="text-center">
                    <a href="#!" className="load-more btn">
                      Load More <i className="icon ion-md-arrow-down"></i>
                    </a>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
