/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-state */
/* eslint-disable radix */
/* eslint-disable react/no-access-state-in-setstate */

import React, { Component } from "react";
import { Row, Col, Nav } from "react-bootstrap";
import { Link, useParams, Navigate, Redirect } from "react-router-dom";

import { swapApi } from "../../services/SwapService";
import QRCode from "react-qr-code";
// import loader from '../../assets/images/editor.gif';

function withParams(Comp) {
  // eslint-disable-next-line func-names
  return function (props) {
    // eslint-disable-next-line react/jsx-filename-extension
    return <Comp {...props} params={useParams()} />;
  };
}
class Deposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      order_id: "",
      sendCoin: "",
      sendCoinNetworkName: "",
      sendAmount: "",
      sendAddress: "",
      receiveCoin: "",
      receiveCoinNetworkName: "",
      receiveAmount: "",
      receiveAddress: "",
      networkFee: "",
      // expireLeft: '',
      destId: "",
      status: "",
      count: 0,
      countDepositExchange: 0,
      countTradeDeposit: 0,
      countReceiveTransaction: 0,
      pairCount: 0,
      countUpdateFURA: 0,
      receiveTransactionId: "",
      depositExchangeTransId: "",
      depositExchangeTransFee: 0,
      withdrawalAmount: 0,
      countWithdeawalAmount: 0,
      timeLeftHours: 0,
      timeLeftMinutes: 0,
      createdAt: 0,
      distance: 0,
      countUpdateSendAmount: 0,
      showTimer: true,
    };
  }

  async componentDidMount() {
    await this.getOrder(this.props.params.order_id);
    try {
      await setInterval(async () => {
        await this.getOrder(this.props.params.order_id);
        // if (this.state.status > 1) {
        //   await this.setState({ showTimer: false });
        // }
        // if (this.state.status === '1') {
        //   if (this.state.timeLeftHours > 0 || this.state.timeLeftMinutes > 0) {
        //     const now_date = new Date();
        //     await this.setState({ distance: this.state.createdAt - now_date });
        //     await this.setState({
        //       timeLeftHours: Math.floor(
        //         (this.state.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        //       ) > 0
        //         ? Math.floor((this.state.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) : 0,
        //     });
        //     if (Math.floor((this.state.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) < 0) {
        //       await this.setState({ timeLeftMinutes: 0 });
        //     } else {
        //       await this.setState({
        //         timeLeftMinutes: Math.floor(
        //           (this.state.distance % (1000 * 60 * 60)) / (1000 * 60),
        //         ) > 0
        //           ? Math.floor((this.state.distance % (1000 * 60 * 60)) / (1000 * 60)) : 0,
        //       });
        //     }
        //     const data = {
        //       orderId: this.state.order_id
        //     };
        //     const transactionDetail = await swapApi.addTransactionDetails(data);
        //     if (
        //       transactionDetail.status === 200
        //       && transactionDetail.data.status === 200
        //       && transactionDetail.data.data[0].id
        //     ) {
        //       const updateData = {
        //         orderId: this.state.order_id,
        //       };

        //       const updateTransactionStatus = await swapApi.getUpdateTransactionStatus(
        //         updateData,
        //       );

        //       if (
        //         updateTransactionStatus.status === 200
        //         && updateTransactionStatus.data.status === 200
        //       ) {
        //         const datas = {
        //           order_id: this.state.order_id,
        //         };

        //         const orderDetail = await swapApi.getOrderDetails(datas);

        //         if (orderDetail.status === 200 && orderDetail.data.status === 200) {
        //           await this.setState({ status: orderDetail.data.data.status });
        //         }
        //       }
        //     }
        //   } else {
        //     const updateData = {
        //       orderId: this.state.order_id
        //     };

        //     const updateTransactionStatus = await swapApi.getUpdateTransactionExpireStatus(
        //       updateData,
        //     );

        //     if ( updateTransactionStatus.status === 200 &&  updateTransactionStatus.data.success === true && updateTransactionStatus.data.status === 200) {
        //       const datas = {
        //         order_id: this.state.order_id,
        //       };

        //       const orderDetail = await swapApi.getOrderDetails(datas);

        //       if (orderDetail.status === 200 && orderDetail.data.status === 200) {
        //         await this.setState({ status: orderDetail.data.data.status });
        //       }
        //     } else if (updateTransactionStatus.status === 200 &&  updateTransactionStatus.data.success === false && updateTransactionStatus.data.msg === "Order not expire now !") {
        //       await this.setState({ timeLeftHours: updateTransactionStatus.data.data.remaingTimeHour});
        //       await this.setState({ timeLeftMinutes: updateTransactionStatus.data.data.remaingTimeMinutes});
        //       const datas = {
        //         order_id: this.state.order_id,
        //       };

        //       const orderDetail = await swapApi.getOrderDetails(datas);

        //       if (orderDetail.status === 200 && orderDetail.data.status === 200) {
        //         await this.setState({ status: orderDetail.data.data.status });
        //       }
        //     }
        //   }
        // } else if (this.state.status === '2') {
        //   const data = {
        //     orderId: this.state.order_id,
        //   };
        //   const checktransactionminimumamount = await swapApi.getTransactionMinimumAmount(data);
        //   if (checktransactionminimumamount.data.status === 400 && checktransactionminimumamount.data.success === false && checktransactionminimumamount.data.errors === "invalid") {
        //     const invalidTransaction = await swapApi.updateInvalidTransaction(data);
        //     if ( invalidTransaction.status === 200 &&  invalidTransaction.data.success === true && invalidTransaction.data.status === 200) {
        //       const datas = {
        //         order_id: this.state.order_id,
        //       };
        //       const orderDetail = await swapApi.getOrderDetails(datas);
        //       if (orderDetail.status === 200 && orderDetail.data.status === 200) {
        //         await this.setState({ status: orderDetail.data.data.status });
        //       }
        //     }
        //   }
        //   if (checktransactionminimumamount.data.status === 200 && checktransactionminimumamount.data.success === true && checktransactionminimumamount.data.msg === "success") {
        //     const transactionDetail = await swapApi.getExternalVaultTransactionDetails(data);
        //     if (transactionDetail.status === 200 && transactionDetail.data.status && transactionDetail.data.data.id && transactionDetail.data.data.status === 'COMPLETED') {
        //       if (this.state.countUpdateSendAmount <= 0) {
        //         await this.setState({ countUpdateSendAmount: 1 });
        //         const orderSendAmountData = {
        //           orderId: this.state.order_id
        //         };
        //         const updateOrderSendAmount = await swapApi.updateOrderSendAmount(
        //           orderSendAmountData,
        //         );
        //         if (updateOrderSendAmount.status === 200 && updateOrderSendAmount.data.status === 200) {
        //           const datas = {
        //             order_id: this.state.order_id,
        //           };

        //           const orderDetail = await swapApi.getOrderDetails(datas);

        //           if (orderDetail.status === 200 && orderDetail.data.status === 200) {
        //             await this.setState({ sendAmount: orderDetail.data.data.amount_send });
        //             await this.setState({ receiveAmount: orderDetail.data.data.IURA });
        //             this.getDepositExchange(this.state.sendAmount);
        //           }
        //         }
        //       }
        //     }
        //   }
        // } else if (this.state.status === '3') {
        //   await this.depositTrade();
        // } else if (this.state.status === '4') {
        //   await this.updateOrderFURA();
        // } else if (this.state.status === '5') {
        //   await this.withdeawalTradeAmount();
        // } else if (this.state.status === '6') {
        //   const data = {
        //     orderId: this.state.order_id,
        //   };
        //   this.placeReceiveAsset(data);
        // } else if (this.state.status === '7') {
        //   await this.getReceiveTransactionDetail(this.state.order_id);
        // } else if (this.state.status === '8') {
        //   await this.setState({ redirect: true });
        // } else if (this.state.status === '9') {
        //   await this.setState({ redirect: true });
        // } else if (this.state.status === '10') {
        //   await this.setState({redirect: true });
        // }
      }, 30000);
    } catch (error) {
      console.log(error);
    }
  }

  getOrder = async (order_id) => {
    const data = {
      order_id,
    };
    const orderDetail = await swapApi.getOrderDetails(data);
    if (orderDetail.status === 200) {
      this.setState({ order_id: orderDetail.data.data.order_id });
      this.setState({ sendAmount: orderDetail.data.data.amount_send });
      this.setState({ sendCoin: orderDetail.data.data.coin_send });
      this.setState({
        sendCoinNetworkName: orderDetail.data.data.coin_send_network,
      });
      this.setState({ sendAddress: orderDetail.data.data.server_address });

      this.setState({ receiveAmount: orderDetail.data.data.amount_receive });
      this.setState({ receiveCoin: orderDetail.data.data.coin_receive });
      this.setState({
        receiveCoinNetworkName: orderDetail.data.data.coin_receive_network,
      });
      this.setState({ receiveAddress: orderDetail.data.data.recipient });
      this.setState({ destId: orderDetail.data.data.destId });
      this.setState({ status: orderDetail.data.data.status });
      this.setState({
        depositExchangeTransId: orderDetail.data.data.depositExchangeTransId,
      });
      await this.setState({
        receiveTransactionId: orderDetail.data.data.transId,
      });
      await this.setState({
        depositExchangeTransFee: orderDetail.data.data.depositExchangeTransFee,
      });
      await this.setState({
        withdrawalAmount: orderDetail.data.data.withdrawalAmount,
      });
      await this.setState({ networkFee: orderDetail.data.data.network_fee });
      await this.setState({ sendAmount: orderDetail.data.data.amount_send });

      const create_at = new Date(orderDetail.data.data.created_at);
      create_at.setTime(create_at.getTime() + 3 * 60 * 60 * 1000);
      await this.setState({ createdAt: create_at });
      const now_date = new Date();
      await this.setState({ distance: this.state.createdAt - now_date });
      await this.setState({
        timeLeftHours:
          Math.floor(
            (this.state.distance % (1000 * 60 * 60 * 3)) / (1000 * 60 * 60)
          ) >= 0
            ? Math.floor(
                (this.state.distance % (1000 * 60 * 60 * 3)) / (1000 * 60 * 60)
              )
            : 0,
      });

      if (
        Math.floor(
          (this.state.distance % (1000 * 60 * 60 * 3)) / (1000 * 60 * 60)
        ) < 0
      ) {
        await this.setState({ timeLeftMinutes: 0 });
      } else {
        await this.setState({
          timeLeftMinutes:
            Math.floor((this.state.distance % (1000 * 60 * 60)) / (1000 * 60)) >
            0
              ? Math.floor(
                  (this.state.distance % (1000 * 60 * 60)) / (1000 * 60)
                )
              : 0,
        });
      }
      if (orderDetail.data.data.status > 1) {
        await this.setState({ showTimer: false });
      }

      if (
        this.state.status === "8" ||
        this.state.status === "9" ||
        this.state.status === "10"
      ) {
        await this.setState({ redirect: true });
      }
    }
  };

  // // Deposit amount from FB vault to Exchange
  // getDepositExchange = async (amount) => {
  //   if (this.state.countDepositExchange <= 0) {
  //     await this.setState({ countDepositExchange: 1 });
  //     const depositExchangeData = {
  //       orderId: this.state.order_id,
  //     };
  //     const depositExchange = await swapApi.getDepositExchange(depositExchangeData);
  //     if (depositExchange.status === 200 && depositExchange.data.status === 200) {
  //       await this.setState({ depositExchangeTransId: depositExchange.data.data.id });
  //       const updateData = {
  //         orderId: this.state.order_id,
  //       };
  //       const updateTransactionStatus = await swapApi.getUpdateTransactionStatus(
  //         updateData,
  //       );

  //       if (
  //         updateTransactionStatus.status === 200
  //         && updateTransactionStatus.data.status === 200
  //       ) {
  //         const datas = {
  //           order_id: this.state.order_id,
  //         };

  //         const orderDetail = await swapApi.getOrderDetails(datas);

  //         if (orderDetail.status === 200 && orderDetail.data.status === 200) {
  //           await this.setState({ status: orderDetail.data.data.status });
  //           await this.setState({
  //             depositExchangeTransFee: orderDetail.data.data.depositExchangeTransFee,
  //           });
  //         }
  //       }
  //     } else {
  //       console.log('else');
  //     }
  //   }
  // };

  // // check exchange transaction details
  // depositTrade = async () => {
  //   const depositExchangeTransData = {
  //     orderId: this.state.order_id,
  //   };
  //   const getDepositExchangeTransDetail = await
  //   swapApi.getDepositExchangeTransactionDetail(depositExchangeTransData);

  //   if (
  //     getDepositExchangeTransDetail.status === 200
  //     && getDepositExchangeTransDetail.data.status === 200
  //     && getDepositExchangeTransDetail.data.data.status === 'COMPLETED'
  //   ) {
  //     if (this.state.countTradeDeposit <= 0) {
  //       await this.setState({ countTradeDeposit: 1 });
  //       const data = {
  //         orderId: this.state.order_id,
  //       };
  //       const transfer = await swapApi.transferDeposit(data);
  //       if (transfer.status === 200 && transfer.data.status === 200) {
  //         const updateData = {
  //           orderId: this.state.order_id,
  //         };

  //         const updateTransactionStatus = await swapApi.getUpdateTransactionStatus(
  //           updateData,
  //         );

  //         if (
  //           updateTransactionStatus.status === 200
  //         && updateTransactionStatus.data.status === 200
  //         ) {
  //           const datas = {
  //             order_id: this.state.order_id,
  //           };

  //           const orderDetail = await swapApi.getOrderDetails(datas);

  //           if (orderDetail.status === 200 && orderDetail.data.status === 200) {
  //             await this.setState({ status: orderDetail.data.data.status });
  //           }
  //         }
  //       } else {
  //         console.log('error = ', transfer.data.errors[0].msg);
  //       }
  //     }
  //   }
  // };

  // // calculate FURA
  // updateOrderFURA = async () => {
  //   if (this.state.countUpdateFURA <= 0) {
  //     await this.setState({ countUpdateFURA: 1 });
  //     const data = {
  //       destId: this.state.destId,
  //       assets: this.state.sendCoinNetworkName !=='' ? this.state.sendCoinNetworkName : this.state.sendCoin,
  //       destAddress: this.state.sendAddress,
  //     };

  //     const transactionDetail = await swapApi.getTransactionDetails(data);
  //     if (
  //       transactionDetail.status === 200
  //       && transactionDetail.data.status === 200
  //       && transactionDetail.data.data.length > 0
  //       && transactionDetail.data.data[0].status === 'COMPLETED'
  //     ) {
  //       const updateFURAData = {
  //         orderId: this.state.order_id,
  //         sendAmount: transactionDetail.data.data[0].amount,
  //         coin_send: this.state.sendCoin,
  //         coin_send_network: this.state.sendCoinNetworkName,
  //         coin_receive: this.state.receiveCoin,
  //         coin_receive_netowrk: this.state.receiveCoinNetworkName,
  //         destId: this.state.destId,
  //         estimateFee: this.state.depositExchangeTransFee,
  //       };
  //       const getUpdateOrderFURA = await swapApi.updateOrderFURA(updateFURAData);
  //       if (getUpdateOrderFURA.status === 200 && getUpdateOrderFURA.data.status === 200) {
  //         const updateData = {
  //           orderId: this.state.order_id,
  //         };

  //         const updateTransactionStatus = await swapApi.getUpdateTransactionStatus(
  //           updateData,
  //         );

  //         if (
  //           updateTransactionStatus.status === 200
  //             && updateTransactionStatus.data.status === 200
  //         ) {
  //           const datas = {
  //             order_id: this.state.order_id,
  //           };

  //           const orderDetail = await swapApi.getOrderDetails(datas);

  //           if (orderDetail.status === 200 && orderDetail.data.status === 200) {
  //             await this.setState({ status: orderDetail.data.data.status });
  //             await this.setState({ sendAmount: orderDetail.data.data.amount_send });
  //             await this.setState({ receiveAmount: orderDetail.data.data.amount_receive });
  //             await this.setState({ withdrawalAmount: orderDetail.data.data.withdrawalAmount });
  //           }
  //         }
  //       } else {
  //         console.log('else');
  //       }
  //     }
  //   }
  // };

  // withdeawalTradeAmount = async () => {
  //   if (this.state.countWithdeawalAmount <= 0) {
  //     await this.setState({ countWithdeawalAmount: 1 });
  //     const data = {
  //       orderId: this.state.order_id,
  //     };
  //     const transfer = await swapApi.transferWithdrawal(data);
  //     if (transfer.status === 200 && transfer.data.status === 200) {
  //       const updateData = {
  //         orderId: this.state.order_id,
  //       };

  //       const updateTransactionStatus = await swapApi.getUpdateTransactionStatus(
  //         updateData,
  //       );

  //       if (
  //         updateTransactionStatus.status === 200
  //       && updateTransactionStatus.data.status === 200
  //       ) {
  //         const datas = {
  //           order_id: this.state.order_id,
  //         };

  //         const orderDetail = await swapApi.getOrderDetails(datas);

  //         if (orderDetail.status === 200 && orderDetail.data.status === 200) {
  //           await this.setState({ status: orderDetail.data.data.status });
  //         }
  //       }
  //     } else {
  //       console.log('error = ', transfer.data.errors[0].msg);
  //     }
  //   }
  // };

  // // Receive coin
  // placeReceiveAsset = async (data) => {
  //   if (this.state.countReceiveTransaction <= 0) {
  //     await this.setState({ countReceiveTransaction: 1 });
  //     const receiveTransaction = await swapApi.receiveTransaction(data);
  //     if (receiveTransaction.status === 200 && receiveTransaction.data.status === 200) {
  //       await this.setState({ receiveTransactionId: receiveTransaction.data.data.id });
  //       const updateData = {
  //         orderId: this.state.order_id,
  //       };

  //       const updateTransactionStatus = await swapApi.getUpdateTransactionStatus(
  //         updateData,
  //       );

  //       if (
  //         updateTransactionStatus.status === 200
  //         && updateTransactionStatus.data.status === 200
  //       ) {
  //         const datas = {
  //           order_id: this.state.order_id,
  //         };

  //         const orderDetail = await swapApi.getOrderDetails(datas);

  //         if (orderDetail.status === 200 && orderDetail.data.status === 200) {
  //           await this.setState({ status: orderDetail.data.data.status });
  //         }
  //       }
  //     }
  //   }
  // };

  // // Receive transaction details
  // getReceiveTransactionDetail = async (orderId) => {
  //   const getReceiveTransDetail = await swapApi.getTransactionDetail(orderId);
  //   if (getReceiveTransDetail.status === 200 && getReceiveTransDetail.data.status === 200 && getReceiveTransDetail.data.data.status === 'CONFIRMING') {
  //     const data = {
  //       orderId: this.state.order_id,
  //     };
  //     const updateOrderTxHash = await swapApi.updateReceiveTransactionTxHash(data);
  //     if (updateOrderTxHash.status === 200 && updateOrderTxHash.data.status === 200) {
  //       const updateData = {
  //         orderId: this.state.order_id,
  //       };
  //       const updateTransactionStatus = await swapApi.getUpdateTransactionStatus(
  //         updateData,
  //       );
  //       if (
  //         updateTransactionStatus.status === 200
  //         && updateTransactionStatus.data.status === 200
  //       ) {
  //         const datas = {
  //           order_id: this.state.order_id,
  //         };
  //         const orderDetail = await swapApi.getOrderDetails(datas);
  //         if (orderDetail.status === 200 && orderDetail.data.status === 200) {
  //           await this.setState({ status: orderDetail.data.data.status });
  //         }
  //       }
  //     }
  //   }
  // };

  render() {
    const isPwa = window.matchMedia("(display-mode: standalone)");

    return isPwa.matches ? (
      <>
        {" "}
        {this.state.redirect && (
          <Redirect to={`/receipt/${this.state.order_id}`} />
        )}
        <div
          className="col-sm-2 col-md-2 side_menu2 swap-header-contr swap-header-contr-pwa"
          style={{ position: "fixed", top: "0px", zIndex: "71000000" }}
        >
          <div
            style={{ display: "flex" }}
            onClick={() => {
              window.location.href = "/swap";
            }}
          >
            <i className=" ion ion-ios-arrow-back" />
          </div>
          <ol className="text-center tree-structure2">
            <li>
              <span className="num active">1</span>
            </li>
            <li>
              <span className="num active">2</span>
            </li>
            <li style={{ paddingRight: "0px" }}>
              <span className="num">3</span>
            </li>
          </ol>
        </div>
        <section>
          <div className="container-fluid-pwa">
            <div className="row" style={{ marginTop: "2%" }}>
              <div className="col-sm-10 col-md-10">
                <div className="sendBoxArea-pwa">
                  <div
                    className="orderId-pwa"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h4
                      className="plain-text"
                      style={{
                        margin: "0px",
                      }}
                    >
                      TradeID:
                      <span>{this.state.order_id}</span>
                    </h4>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(this.state.order_id);
                      }}
                    >
                      <i className=" mdi mdi-content-copy" />
                    </span>
                  </div>
                  <div
                    className="sendAmountArea"
                    style={{ padding: "10px", borderRadius: "5px" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-4 col-md-4 col-sm-12 col-12"
                        style={{ marginBottom: "5px" }}
                      >
                        <div
                          className="sendAmountAreaRight d-flex flex-wrap"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "72%" }}>
                            <p style={{ lineHeight: "1.2", fontSize: "14px" }}>
                              If exect amount is not sent, it will not be
                              automatically detected and we have manually
                              approve it{" "}
                            </p>
                          </div>
                          <div
                            className="amtLeft"
                            style={{ flex: "none", width: "fit-content" }}
                          >
                            <span>
                              <QRCode
                                size={256}
                                style={{
                                  height: "auto",
                                  maxWidth: "65px",
                                  width: "100%",
                                }}
                                value={this.state.sendAddress}
                                viewBox={`0 0 256 256`}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                        <div className="sendAmountAreaLeft">
                          <p
                            className="plain-text"
                            style={{
                              fontWeight: "700",
                              paddingBottom: "0px",
                            }}
                          >
                            {this.state.status > "1" && this.state.status < 9
                              ? "You sent this amount"
                              : "Send this amount:"}
                          </p>
                          <h2
                            className="plain-text"
                            style={{
                              fontSize: "1.1rem",
                              paddingBottom: "10px",
                            }}
                          >
                            {this.state.sendAmount} {this.state.sendCoin}
                            <span
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `${this.state.sendAmount} ${this.state.sendCoin}`
                                );
                              }}
                            >
                              <i className=" mdi mdi-content-copy" />
                            </span>
                          </h2>
                          <h5
                            className="plain-text"
                            style={{
                              fontSize: "1.1rem",
                              paddingBottom: "0px",
                            }}
                          >
                            To this address:
                          </h5>
                          <h3
                            className="plain-text"
                            style={{
                              wordBreak: "break-all",
                              wordBreak: "break-all",
                              fontSize: "15px",
                              width: "100%",
                            }}
                          >
                            {this.state.sendAddress}
                            <span
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `${this.state.sendAmount} ${this.state.sendCoin}`
                                );
                              }}
                            >
                              <i className=" mdi mdi-content-copy" />
                            </span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="youReceive" style={{ marginTop: "5px" }}>
                    <p className="plain-text">You Get:</p>
                    <h6 className="plain-text" style={{ marginTop: "5px" }}>
                      ≈{this.state.receiveAmount} {this.state.receiveCoin}
                    </h6>
                    <p
                      className="plain-text"
                      style={{ lineHeight: "16px", marginBottom: "5px" }}
                    >
                      Rate locks after "Confirming" the stage (usually 1
                      confirmation).
                    </p>
                    <p className="plain-text">Recipient Wallet</p>
                    <h4
                      className="plain-text"
                      style={{ wordBreak: "break-all" }}
                    >
                      {this.state.receiveAddress}
                    </h4>
                  </div>
                </div>
                <div className="sendTabArea-pwa">
                  <ul className="row" style={{ display: "flex" }}>
                    <li
                      className="col-sm-12 col-md-3 process-bar start"
                      style={{ width: "fit-content" }}
                    >
                      {Number(this.state.status) === 1 ? (
                        <>
                          <div className="text-center">
                            <img
                              className="deposit-bottom-logo"
                              src={process.env.REACT_APP_LOADER}
                            />
                          </div>
                          <div
                            className="text-center deposit-bottom-text plain-text"
                            style={{ fontSize: "14px", paddingInline: "3px" }}
                          >
                            Deposit
                          </div>
                        </>
                      ) : (
                        <Link
                          className="text-center plain-text"
                          to="#"
                          style={{ fontSize: "14px", paddingInline: "3px" }}
                        >
                          Deposit
                        </Link>
                      )}
                    </li>
                    <li
                      className="col-sm-12 col-md-3 process-bar"
                      style={{ width: "fit-content" }}
                    >
                      {Number(this.state.status) === 2 ||
                      Number(this.state.status) === 3 ? (
                        <>
                          <div className="text-center">
                            <img
                              className="deposit-bottom-logo"
                              src={process.env.REACT_APP_LOADER}
                            />
                          </div>
                          <div
                            className="text-center deposit-bottom-text plain-text"
                            style={{ fontSize: "14px", paddingInline: "3px" }}
                          >
                            Confirm
                          </div>
                        </>
                      ) : (
                        <Link
                          className="text-center plain-text"
                          to="#"
                          style={{ fontSize: "14px", paddingInline: "3px" }}
                        >
                          Confirm
                        </Link>
                      )}
                    </li>
                    <li
                      className="col-sm-12 col-md-3 process-bar"
                      style={{ width: "fit-content" }}
                    >
                      {Number(this.state.status) === 4 ||
                      Number(this.state.status) === 5 ||
                      Number(this.state.status) === 6 ? (
                        <>
                          <div className="text-center">
                            <img
                              className="deposit-bottom-logo"
                              src={process.env.REACT_APP_LOADER}
                            />
                          </div>
                          <div
                            className="text-center deposit-bottom-text plain-text"
                            style={{ fontSize: "14px", paddingInline: "3px" }}
                          >
                            Swap
                          </div>
                        </>
                      ) : (
                        <Link
                          className="text-center plain-text"
                          to="#"
                          style={{ fontSize: "14px", paddingInline: "3px" }}
                        >
                          Swap
                        </Link>
                      )}
                    </li>
                    <li
                      className="col-sm-12 col-md-3 process-bar-end"
                      style={{ width: "fit-content" }}
                    >
                      {Number(this.state.status) === 7 ? (
                        <>
                          <div className="text-center">
                            <img
                              className="deposit-bottom-logo"
                              src={process.env.REACT_APP_LOADER}
                            />
                          </div>
                          <div
                            className="text-center deposit-bottom-text plain-text"
                            style={{ fontSize: "14px", paddingInline: "3px" }}
                          >
                            Send
                          </div>
                        </>
                      ) : (
                        <Link
                          className="text-center plain-text"
                          to="#"
                          style={{ fontSize: "14px", paddingInline: "3px" }}
                        >
                          Send
                        </Link>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    ) : (
      <>
        {this.state.redirect && (
          <Redirect to={`/receipt/${this.state.order_id}`} />
        )}
        <section>
          <div className="container-fluid">
            <div className="row" style={{ marginTop: "2%" }}>
              <div
                className="col-sm-10 col-md-1 menu_option"
                style={{ marginBottom: "2%" }}
              >
                <Nav
                  variant="pills"
                  className="swap-nav"
                  style={{ boxShadow: "none" }}
                >
                  <Nav.Item style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Nav.Link>Choose</Nav.Link>
                  </Nav.Item>
                  <Nav.Item style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Nav.Link>Send</Nav.Link>
                  </Nav.Item>
                  <Nav.Item style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Nav.Link>Receive</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-md-1 side_menu" style={{ marginTop: "2%" }}>
                <ol className="text-center tree-structure">
                  <li>
                    <span className="num">1</span>
                    <ol></ol>
                  </li>
                  <li>
                    <span
                      className="num"
                      style={{ background: "#428bca", color: "#fff" }}
                    >
                      2
                    </span>
                    <ol></ol>
                  </li>
                  <li>
                    <span className="num">3</span>
                    <ol></ol>
                  </li>
                </ol>
              </div>
              <div className="col-sm-2 col-md-2 side_menu2">
                <ol className="text-center tree-structure2">
                  <li>
                    <span className="num">1</span>
                  </li>
                  <li>
                    <span
                      className="num"
                      style={{ background: "#428bca", color: "#fff" }}
                    >
                      2
                    </span>
                  </li>
                  <li>
                    <span className="num">3</span>
                  </li>
                </ol>
                <ol className="text-center tree-structure">
                  <li>
                    <span className="num">1</span>
                    <ol></ol>
                  </li>
                  <li>
                    <span
                      className="num"
                      style={{ background: "#428bca", color: "#fff" }}
                    >
                      2
                    </span>
                    <ol></ol>
                  </li>
                  <li>
                    <span className="num">3</span>
                    <ol></ol>
                  </li>
                </ol>
              </div>
              <div className="col-sm-10 col-md-10">
                <div className="sendBoxArea">
                  <div className="orderId">
                    <h4 className="plain-text">
                      OrderId:
                      <span>{this.state.order_id}</span>
                    </h4>
                  </div>
                  <div className="sendAmountArea">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                        <div className="sendAmountAreaLeft">
                          <h5 className="plain-text">
                            {this.state.status > "1" && this.state.status < 9
                              ? "You sent this amount"
                              : "Send this amount:"}
                          </h5>
                          <h2 className="plain-text">
                            {this.state.sendAmount} {this.state.sendCoin}
                          </h2>
                          <h5 className="plain-text">To this address:</h5>
                          <h3
                            className="plain-text"
                            style={{ wordBreak: "break-all" }}
                          >
                            {this.state.sendAddress}
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="sendAmountAreaRight d-flex flex-wrap">
                          <div className="amtLeft">
                            <span>
                              <QRCode
                                size={256}
                                style={{
                                  height: "auto",
                                  maxWidth: "100%",
                                  width: "100%",
                                }}
                                value={this.state.sendAddress}
                                viewBox={`0 0 256 256`}
                              />
                            </span>
                          </div>
                          <div className="amtRight">
                            {this.state.showTimer ? (
                              <>
                                <h5 className="plain-text">Time left:</h5>
                                <p className="plain-text">
                                  {this.state.timeLeftHours > 0
                                    ? `${this.state.timeLeftHours} Hours ${this.state.timeLeftMinutes} Minutes`
                                    : `${this.state.timeLeftMinutes} Minutes`}
                                </p>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="youReceive">
                    <p className="plain-text">You Receive</p>
                    <h6 className="plain-text">
                      ≈{this.state.receiveAmount} {this.state.receiveCoin}
                    </h6>
                    <p className="plain-text">
                      The rate locks after the exchanging stage (see below).
                    </p>
                    <p className="plain-text">Recipient Wallet</p>
                    <h4
                      className="plain-text"
                      style={{ wordBreak: "break-all" }}
                    >
                      {this.state.receiveAddress}
                    </h4>
                  </div>
                </div>

                <div className="sendTabArea">
                  <ul className="row">
                    <li className="col-sm-12 col-md-3">
                      {Number(this.state.status) === 1 ? (
                        <>
                          <div className="text-center">
                            <img
                              className="deposit-bottom-logo"
                              src={process.env.REACT_APP_LOADER}
                            />
                          </div>
                          <div className="text-center deposit-bottom-text plain-text">
                            Deposit
                          </div>
                        </>
                      ) : (
                        <Link className="text-center plain-text" to="#">
                          Deposit
                        </Link>
                      )}
                    </li>
                    <li className="col-sm-12 col-md-3">
                      {Number(this.state.status) === 2 ||
                      Number(this.state.status) === 3 ? (
                        <>
                          <div className="text-center">
                            <img
                              className="deposit-bottom-logo"
                              src={process.env.REACT_APP_LOADER}
                            />
                          </div>
                          <div className="text-center deposit-bottom-text plain-text">
                            Confirming
                          </div>
                        </>
                      ) : (
                        <Link className="text-center plain-text" to="#">
                          Confirming
                        </Link>
                      )}
                    </li>
                    <li className="col-sm-12 col-md-3">
                      {Number(this.state.status) === 4 ||
                      Number(this.state.status) === 5 ||
                      Number(this.state.status) === 6 ? (
                        <>
                          <div className="text-center">
                            <img
                              className="deposit-bottom-logo"
                              src={process.env.REACT_APP_LOADER}
                            />
                          </div>
                          <div className="text-center deposit-bottom-text plain-text">
                            Exchanging
                          </div>
                        </>
                      ) : (
                        <Link className="text-center plain-text" to="#">
                          Exchanging
                        </Link>
                      )}
                    </li>
                    <li className="col-sm-12 col-md-3">
                      {Number(this.state.status) === 7 ? (
                        <>
                          <div className="text-center">
                            <img
                              className="deposit-bottom-logo"
                              src={process.env.REACT_APP_LOADER}
                            />
                          </div>
                          <div className="text-center deposit-bottom-text plain-text">
                            Receive
                          </div>
                        </>
                      ) : (
                        <Link className="text-center plain-text" to="#">
                          Receive
                        </Link>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default withParams(Deposit);
