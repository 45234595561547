import { Badge, Col, Container, Row } from 'react-bootstrap';
import CountUp from 'react-countup';

// images
import feature from '../../../assets/images/me.jpg';
import linkedinLogo from '../../../assets/images/linkedin.svg'; // Import the LinkedIn logo

// styles
import '../../../assets/scss/style.scss';

const Counter = () => {
    return (
        <section className="pt-8 pb-6 mb-4 mt-lg-4 position-relative" data-aos="fade-up">
            <Container>
                <Row>
                    <Col className="text-center">
                        <Badge pill className="badge-soft-info px-2 py-1">
                            Our Founder
                        </Badge>
                        <h1 className="display-5 fw-medium clrwhite">Meet Our Founder</h1>
                    </Col>
                </Row>
                <Row className="align-items-center mt-5">
                    <Col xs={12} md={4}>
                        <div className='founderImage'>
                          <span>
                            <img src={feature} alt="founder" />
                          </span>
                          <h1 className="clrwhite">
                            Andrew Elkhoury
                            <a href="https://www.linkedin.com/in/andrew-elkhoury/" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }}>
                                <img src={linkedinLogo} alt="LinkedIn" style={{ width: '24px', verticalAlign: 'middle', filter: 'invert(25%) sepia(100%) saturate(4500%) hue-rotate(210deg) brightness(95%) contrast(90%)' }} />
                            </a>
                          </h1>
                          <h6 className="clrwhite">CEO & CTO</h6>
                        </div>
                    </Col>
                    <Col xs={12} md={8} className="clrwhite">
                        <p>Beginning his experience in 2012 as a financial trader, Andrew began to broaden his knowledge in financial technology. Having used multiple electronic trading platforms ranging from CFD platforms to online brokerages, 
Andrew settled with Interactive Brokers as he believes users are best suited owning the underlying asset rather than speculating on empty price differences. Since 2017 Andrew has worked as a Systems/DevOps Engineer, which allowed him to build the foundational knowledge he now uses to lead the development of Quantex. 
In October 2020, Andrew began developing Cryptiswap, which was the predecessor to Quantex. Cryptiswap was a non-custodial swap platform, which was launched in April 2021.
</p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Counter;
